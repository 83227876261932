import React from "react";
import { Col, Row, Input, Modal, Form, Radio, Alert, Select } from 'antd';
import { URL_DELIVERY, URL_INFLORESCENCE } from "../constants/urls";
import { PATCH, POST } from "../frameworks/HttpClient";
import useDropdownItem from "../hooks/useDropdownItem";
import * as _ from 'lodash'
import QRCodeSelect from "../components/common/QRCodeSelect";
import QRCodeSearch from "../components/common/QRCodeSearch";

export default function EvaluateBunchModal(props) {
  const { open, onClose, target, shippingData, onUpdate } = props;
  const isNoTarget = (target && target === 'add')
  const [errorMessages, setErrorMessages] = React.useState(null);
  const palmBunchGradeDropdown = useDropdownItem({ url: URL_INFLORESCENCE.PALMBUNCH_GRADE, label: 'grade', pagination: true, params: { page_size: 999, gender : 'female'} })
  const [validateResults, setValidateResults] = React.useState(null);
  const [debouncedStock, setDebouncedStock] = React.useState(null)
  const [form] = Form.useForm();
  const InflorescenceWatch = Form.useWatch('Inflorescence', form);
  const isQCPassedWatch = Form.useWatch('is_qc_passed', form);

  const debounceStock = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedStock(_searchVal)
    }, 900),
    []
  )

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const handleSave = async() => {
    setErrorMessages(null)
    try {
      let data = await form.validateFields();
      data['stock'] = _.get(validateResults, 'stock', null)
      await PATCH(`${URL_DELIVERY.INFLORESCENCE_INFLORESCENCE}${form.getFieldValue('Inflorescence')}/`, data)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const checkPillinateStockValidate = async() => {
    setErrorMessages(null)
    try {
      if(debouncedStock && form.getFieldValue('Inflorescence')){
        const respones = await POST(`${URL_INFLORESCENCE.POLLINATION_STOCK_VALIDATE}${form.getFieldValue('Inflorescence')}/`, { stock_number : debouncedStock})
        setValidateResults(respones.data)
        form.setFieldValue('is_qc_passed', null)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
      form.setFieldValue('is_qc_passed', false)
    }
  }

  React.useEffect(() => {
    if(open){
      setValidateResults(null)
      form.resetFields();
      palmBunchGradeDropdown.fetchOptions();
      setDebouncedStock(null)
    } else {
      setErrorMessages(null)
    }
  },[open])

  React.useEffect(() => {
    if(!isNoTarget){
      form.setFieldValue('Inflorescence', target && target.inflorescence)
    }
  },[isNoTarget])

  React.useEffect(() => {
    if(debouncedStock && form.getFieldValue('Inflorescence')){
      checkPillinateStockValidate()
    } else {
      form.setFieldValue('is_qc_passed', null)
    }
    }, [debouncedStock, InflorescenceWatch])

  return (
      <Modal
        open={open}
        onCancel={onClose}
        title={'รับทะลาย'}
        okText={'รับเข้า'}
        cancelText={'ยกเลิก'}
        maskClosable={false}
        width={600}
        onOk={handleSave}
        okButtonProps={{disabled : errorMessages ? true : false}}
      >
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }

      {_.get(validateResults, 'success', null) === false && 
        <Alert
          message={_.get(validateResults, 'detail', null)}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }
      <Form
        form={form}
        layout="vertical">
      <Row gutter ={16}>  
        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name="Inflorescence"
            label="รหัสช่อ"
            rules={[{ required: true}]}>
            <QRCodeSelect
              fetchUrl={URL_INFLORESCENCE.INFLORESCENCE}
              fetchParams={{ 
                is_active: true,
                delivery_transaction : shippingData ? shippingData.id : "none",
                page_size: 999
              }}
              optionLabel={'code'}
              placeholder='เลือกช่อ'
              disabled={isNoTarget ? false : true}/>
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name='stock'
            label='Lot เกสร'>
              <QRCodeSearch onChange={(e) => debounceStock(_.get(e, 'target.value', e))}/>
          </Form.Item>
        </Col>

        <Col md={24} sm={24} xs={24} style={{ padding : 12}}>
          <Form.Item
            name='is_qc_passed'
            label='ผลการประเมิน'
            rules={[{ required: true }]}
          >
          <Radio.Group>
            <Radio value={true} disabled={_.get(validateResults, 'success', false) ? false : true}>ผ่าน</Radio>
            <Radio value={false}>ไม่ผ่าน</Radio>
          </Radio.Group>
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name='grade'
            label='เกรดทะลาย'
            rules={[{ required: isQCPassedWatch ? true : false }]}>
            <Select
              showSearch
              allowClear={'-'}
              initialvalues={'-'}
              autoComplete='off'
              filterOption={filterOption}
              options={palmBunchGradeDropdown.options}
              loading={palmBunchGradeDropdown.loading}
            />
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name='weight'
            label='น้ำหนักทะลาย (กก.)'
            rules={[{ required: isQCPassedWatch ? true : false }]}>
            <Input />
          </Form.Item>
        </Col>
        
        <Col md={24} sm={24} xs={24}>
          <Form.Item
            name='note'
            label='หมายเหตุ'
            rules={[{ required: isQCPassedWatch ? false : true }]}>
            <Input.TextArea />
          </Form.Item>
        </Col>

      </Row>
      </Form>
    </Modal>
  )
}