import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { QrcodeOutlined }  from '@ant-design/icons'
import * as _ from 'lodash';


const ScanQRButton = React.forwardRef((props, ref) => {
  const propsWithDefaults = { ...defaultProps, ...props };

  const {
    inputRef,
    disabled,
    setIsActive,
  } = propsWithDefaults;

  // check scan support by check "jsInterface" in window
  const isClientSupported = _.get(window, 'JSInterface', false) ? true : false;

  const onOpenQRCodeScanner = () => {
    if (isClientSupported) {
      // if client support js interface 
      // then call scanbarcode function and set focus on input
      window.JSInterface.scanBarcode();
      setIsActive(true);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }

  React.useImperativeHandle(ref, () => ({
    isClientSupported: isClientSupported,
    openScan: onOpenQRCodeScanner,
  }));

  return (
    <Tooltip title={isClientSupported ? '' : 'Browser ไม่รองรับ qrcode'}>
      <span>
        <Button type='primary' disabled={!isClientSupported || disabled} onClick={onOpenQRCodeScanner}>
          <QrcodeOutlined />
        </Button>
      </span>
    </Tooltip>
  );
});

const defaultProps = {
  inputRef: null,
  disabled: false,
  setIsActive: () => null,
};

ScanQRButton.propTypes = {
  inputRef: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  setIsActive: PropTypes.func,
}

export default ScanQRButton;