import React from "react";
import propTypes from "prop-types";
import { Button, Col, Modal, Row, theme, Collapse } from "antd";
import { REPORT_FILTER, REPORT_FILTER_TH } from "../constants/strings";
import { URL_REPORT } from "../constants/urls";
import Lottie from "lottie-react";
import lottieReport from "../assets/lottie_report.json"
import ReportModal from "../components/report/ReportModal";
import Header from "../components/common/Header";

export default function ReportView(props) {
  const [targetReport, setTargetReport] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [items, setItems] = React.useState([]);

  const {
		token: { colorInfoText }
	} = theme.useToken();

  const palmReports = [
    {
      id: 1, 
      label: "1. รายงานจำนวนต้นพ่อแม่พันธุ์", 
      detail: "รายงานจำนวนต้นพ่อแม่พันธุ์",
      filters: [
        { name: REPORT_FILTER.TREE_GENDER, placeholder: "ต้นแม่/ต้นพ่อ" },
        { name: REPORT_FILTER.PRODUCTION_TYPE, placeholder: "สถานะการผลิต" },
        { name: REPORT_FILTER.PLANTATION, placeholder: "สวน" },
        { name: REPORT_FILTER.DATE, placeholder: "วันที่เริ่มใช้งาน" },
      ],
      hideExcel: false,
      url: URL_REPORT.PALMTREE_REPORT,
    },
    {
      id: 2, 
      label: "2. สรุปรายงานสถานะช่อดอกประจำเดือน", 
      detail: "สรุปรายงานสถานะช่อดอกประจำเดือน",
      filters: [
        { name: REPORT_FILTER.TREE_GENDER, placeholder: "ต้นแม่/ต้นพ่อ" },
        { name: REPORT_FILTER.PRODUCTION_TYPE, placeholder: "สถานะการผลิต" },
        { name: REPORT_FILTER.PLANTATION, placeholder: "สวน" },
        { name: REPORT_FILTER.SPECIES, placeholder: "พันธุ์ปาล์ม" },
        { name: REPORT_FILTER.DATE_RANGE_3, placeholder: ["คลุมช่อตั้งแต่วันที่", "ถึงวันที่"], required: true }],
      hidePDF: true,
      url: URL_REPORT.BUNCH_SOURCE_REPORT
    },
    {
      id: 3, 
      label: "3. จำนวนช่อดอกและผลผลิตของต้นพ่อแม่พันธุ์", 
      detail: "รายงานจำนวนช่อดอกและผลผลิตของต้นพ่อแม่พันธุ์",
      filters: [
        { name: REPORT_FILTER.DATE_RANGE_2, placeholder: ["ลงทะเบียนตั้งแต่วันที่", "ถึงวันที่"] },
        { name: REPORT_FILTER.PLANTATION, placeholder: "สวน" },
        { name: REPORT_FILTER.TREE_GENDER, placeholder: "ต้นแม่/ต้นพ่อ" },
        { name: REPORT_FILTER.KERNEL_TYPE, placeholder: "รูปแบบกะลา" },
      ],
      hidePDF: true,
      url: URL_REPORT.PALMTREE_SUMMARY_REPORT
    },
    {
      id: 4,
      label: "4. สรุปข้อมูลต้นปาล์ม ต้นพันธุ์ และช่อดอก",
      detail: "รายงานสรุปข้อมูลต้นปาล์มและรายละเอียดช่อดอก",
      filters: [
        { name: REPORT_FILTER.ACTION, placeholder: REPORT_FILTER_TH[REPORT_FILTER.ACTION], required: true },
        { name: REPORT_FILTER.DATE_RANGE_2, placeholder: ["ตั้งแต่วันที่", "ถึงวันที่"], required: true },
        { name: REPORT_FILTER.PLANTATION, placeholder: REPORT_FILTER_TH[REPORT_FILTER.PLANTATION] },
        { name: REPORT_FILTER.TREE_TYPE, placeholder: REPORT_FILTER_TH[REPORT_FILTER.TREE_TYPE] },
        { name: REPORT_FILTER.PALMTREE, placeholder: REPORT_FILTER_TH[REPORT_FILTER.PALMTREE] },
        { name: REPORT_FILTER.IS_QC_PASSED, placeholder: REPORT_FILTER_TH[REPORT_FILTER.IS_QC_PASSED] },
        { name: REPORT_FILTER.PRODUCTION_TYPE, placeholder: "สถานะการผลิต" },
        { name: REPORT_FILTER.SPECIES, placeholder: "พันธุ์ลูกผสม" },
      ],
      hidePDF: true,
      url: URL_REPORT.PALMTREE_BUNCH_SUMMARY_REPORT
    },
  ]

  const stockReports = [
    {
      id: 1, 
      label: "1. ข้อมูลสต๊อกเกสร", 
      detail: "รายงานข้อมูลสต๊อกเกสร",
      filters: [
        { name: REPORT_FILTER.TREE_GENDER, placeholder: "ต้นแม่/ต้นพ่อ" },
        { name: REPORT_FILTER.PRODUCTION_TYPE, placeholder: "สถานะการผลิต" },
        { name: REPORT_FILTER.PLANTATION, placeholder: "สวน" },
        { name: REPORT_FILTER.KERNEL_TYPE, placeholder: "รูปแบบกะลา" },
        { name: REPORT_FILTER.DATE_RANGE_3, placeholder: ["คลุมช่อตั้งแต่วันที่", "ถึงวันที่"] }
      ],
      hidePDF: true,
      url: URL_REPORT.STOCK_REPORT
    },
    {
      id: 2, 
      label: "2. สรุปข้อมูลสต๊อกเกสร", 
      detail: "รายงานสรุปข้อมูลสต๊อกเกสร",
      filters: null,
      hidePDF: true,
      url: URL_REPORT.STOCK_SUMMARY_REPORT
    },
  ]

  const performanceReports = [
    {
      id: 1, 
      label: "1. สูตรผสมเกสร", 
      detail: "รายงานสูตรผสมเกสร",
      filters: [{ name: REPORT_FILTER.DATE_RANGE, placeholder: ["สั่งผสมตั้งแต่วันที่", "ถึงวันที่"], required: true }],
      hidePDF: true,
      url: URL_REPORT.POLLINATIONPLAN_REPORT
    },
    {
      id: 2, 
      label: "2. ใบส่งละอองเกสรตัวผู้เข้าสวน", 
      detail: "รายงานใบส่งละอองเกสรตัวผู้เข้าสวน",
      filters: [{ name: REPORT_FILTER.DATE, placeholder: "วันที่ส่งเกสรเข้าสวน" }],
      required: true,
      url: URL_REPORT.POLLEN_POLLINATION_REPORT
    },
    {
      id: 3, 
      label: "3. บันทึกการสำรวจช่อดอกและทะลายปาล์ม", 
      detail: "รายงานการสำรวจช่อดอกและทะลายปาล์ม",
      filters: [
        { name: REPORT_FILTER.DATE, placeholder: "วันที่สำรวจ", required: true },
        { name: REPORT_FILTER.TREE_GENDER, placeholder: "ต้นแม่/ต้นพ่อ" }
      ],
      url: URL_REPORT.INFLORESCENCE_TASK_ACTION_REPORT 
    },
    {
      id: 4, 
      label: "4. ใบสั่งคลุมช่อ", 
      detail: "รายงานใบสั่งคลุมช่อ",
      filters: [
        { name: REPORT_FILTER.PLANTATION, placeholder: "สวน" },
        { name: REPORT_FILTER.PLAN_DATE, placeholder: "วันที่สั่งคลุมช่อ", required: true }
      ],
      url: URL_REPORT.INFLORESCENCE_COVER_TASK_REPORT
    },
    {
      id: 5, 
      label: "5. ข้อมูลช่อดอกและทะลายลูกผสมบนต้นปาล์ม", 
      detail: "รายงานข้อมูลช่อดอกและทะลายลูกผสมบนต้นปาล์ม",
      filters: [
        { name: REPORT_FILTER.TREE_GENDER, placeholder: "ต้นแม่/ต้นพ่อ" },
        { name: REPORT_FILTER.PRODUCTION_TYPE, placeholder: "สถานะการผลิต" },
        { name: REPORT_FILTER.MULTI_SPECIES, placeholder: "พันธุ์ปาล์ม" },
        { name: REPORT_FILTER.DATE_RANGE_2, placeholder: ["ปฏิบัติงานตั้งแต่วันที่", "ถึงวันที่"], required: true }
      ],
      hidePDF: true,
      url: URL_REPORT.INFLORESCENCE_COVERING_SUMMARY_REPORT
    },
    {
      id: 6, 
      label: "6. รายงานคลุมช่อดอก", 
      detail: "รายงานคลุมช่อดอก",
      filters: [
        { name: REPORT_FILTER.MULTI_PLANTATIONS, placeholder: "สวน" },
        { name: REPORT_FILTER.DATE_RANGE_2, placeholder: ["ปฏิบัติงานตั้งแต่วันที่", "ถึงวันที่"], required: true },
      ],
      hidePDF: true,
      url: URL_REPORT.INFLORESCENCE_COVERING_REPORT
    },
    {
      id: 7, 
      label: "7. ใบสั่งเก็บผลผลิต", 
      detail: "รายงานใบสั่งเก็บผลผลิต",
      filters: [
        { name: REPORT_FILTER.PLANTATION, placeholder: "สวน" },
        { name: REPORT_FILTER.PLAN_DATE, placeholder: "วันที่สั่งตัด", required: true }
      ],
      url: URL_REPORT.INFLORESCENCE_CUT_TASK_REPORT
    },
    {
      id: 8, 
      label: "8. ทะลายทำการตัดและเตรียมส่งโรงงาน", 
      detail: "รายงานทะลายทำการตัดและเตรียมส่งโรงงาน",
      filters: [
        { name: REPORT_FILTER.PLANTATION, placeholder: "สวน" },
        { name: REPORT_FILTER.DATE, placeholder: "วันที่ส่งเข้าโรงงาน", required: true }
      ],
      hideExcel: true,
      url: URL_REPORT.BUNCH_DELIVERY_PREPARING_REPORT
    },
    {
      id: 9, 
      label: "9. บันทึกทะลายที่ไม่ผ่านการตรวจสอบ", 
      detail: "รายงานทะลายที่ไม่ผ่านการตรวจสอบ",
      filters: [
        { name: REPORT_FILTER.DATE_RANGE_2, placeholder: "วันที่ตรวจรับ", required: true },
      ],
      url: URL_REPORT.BUNCH_REJECT_REPORT
    },
  ]

  // Render report list
  const renderReportList = (list) => {
    return (
      <Row 
        gutter={[16, 16]}
        style={{marginTop: 16, marginBottom: 16}}>
        {list.map(item => (
          <Col key={item.id} xl={8} sm={12} xs={24}>
            <Button
              style={{width: "100%", textAlign: "left", borderColor: colorInfoText}}
              disabled={(item && item.url) ? false : true} // disable
              onClick={() => setTargetReport(item)}>
                {item.label}
            </Button>
          </Col>
        ))}
      </Row>
    )
  }

  const manageItems = () => {
    let list = []
    if (!props.disableTree) {
      list.push(
        {
          key: '1',
          label: 'ข้อมูลปาล์ม',
          children: renderReportList(palmReports),
        },
      )
    }
    if (!props.disableStock) {
      list.push(
        {
          key: '2',
          label: 'สต๊อกเกสร',
          children: renderReportList(stockReports),
        },
      )
    }
    if (!props.disableTask) {
      list.push(
        {
          key: '3',
          label: 'ผลการปฏิบัติงาน',
          children: renderReportList(performanceReports),
        },
      )
    }
    setItems(list)
  }

  React.useEffect(() => {
    manageItems()
  }, [props.disableTree, props.disableStock, props.disableTask])

  return(
    <div>
      {targetReport && loading != null &&
				<Modal
          maskClosable={false}
					title={
            <>
              กำลังสร้าง - {targetReport.label}
            </>
          }
					open={loading != null}
					style={{
						top: 20,
					}}
					closable={false}
					footer={[]}>
          <Lottie animationData={lottieReport} />
				</Modal>
			}

    <Header title={"Report"} />
    <Collapse
      defaultActiveKey={['1', '2', '3']}
      items={items} />

      {/* Modal */}
			<ReportModal
				namePrefix={targetReport ? targetReport.label : null}
				open={targetReport ? true : false}
				data={targetReport}
        target={targetReport}
				onStartLoading={() => setLoading(true)}
				onEndLoading={() => setLoading(null)}
				onClose={() => setTargetReport(null)}/>
    </div> 
  )
}

ReportView.defaultProps = {
  disableTree: false,
  disableStock: false,
  disableTask: false,
}

ReportView.propTypes = {
  disableTree: propTypes.bool,
  disableStock: propTypes.bool,
  disableTask: propTypes.bool,
}