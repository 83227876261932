import React from "react";
import { Spin, Layout, Descriptions, theme, Alert, Row, Col, Modal, Button, Form, Flex, Input, message } from 'antd';
import { 
  FileDoneOutlined, 
  CheckCircleFilled, 
  FileExcelOutlined,
  CloseCircleFilled, 
  CheckSquareOutlined, 
  InfoCircleOutlined, 
  FilePdfOutlined, 
  SaveOutlined
} from '@ant-design/icons';
import HeaderScreen from "../components/common/HeaderScreen";
import { useParams, useSearchParams } from "react-router-dom";
import { WHITE_COLOR_BG } from "../constants/color";
import ColorButton from "../components/common/ColorButton";
import TSTable from "../components/common/TSTable";
import EvaluateBunchModal from "../receiver/EvaluateBunchModal";
import { URL_COMMON, URL_DELIVERY, URL_REPORT } from "../constants/urls";
import { GET, PATCH, POST } from "../frameworks/HttpClient";
import * as _ from 'lodash';
import { BUNCH_STATUS, DATETIME_FORMAT, DELIVERY_TRANSACTION_STATE, EXPORT_TYPE, EXPORT_TYPE_TH, HUMAN_DATETIME_FORMAT } from "../constants/strings";
import { dateToString } from "../frameworks/Util";
import EvaluatePollenModal from "../receiver/EvaluaPollenModal";
import { useNavigate } from 'react-router-dom';
import { PATH } from "../routes/CustomRoutes";
import dayjs from "dayjs";
import useDropdownItem from "../hooks/useDropdownItem";

export default function ReceiverDetailScreen() {
  const tableRef = React.useRef();
  const [loading, setLoading] = React.useState(null);
  const [shippingData, setShippingData] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [bunchTarget, setBunchTarget] = React.useState(null);
  const [pollenTarget, setPollenTarget] = React.useState(null);
  const [isInflorescencesDone, setIsInflorescencesDone] = React.useState(null);
  const navigate = useNavigate();
  const { Content, Footer } = Layout;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [fieldView, setFieldView] = React.useState(false);
  const [printing, setPrinting] = React.useState(false);
  const [form] = Form.useForm();

  const {
    token: { colorSuccess, colorInfo, colorError },
  } = theme.useToken();

  const fetchShippingData = async() => {
    setErrorMessages(null)
    setLoading(true)
    try {
      const response = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}${params.id}/`)
      setShippingData(response.data)
      form.setFieldsValue(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }
  
  const refreshData = () => {
    if(tableRef.current)
      tableRef.current.fetch();
      fetchShippingData();
  }

  const handleEvaluateButton = (item) => {
    if(shippingData.export_type === EXPORT_TYPE.POLLEN){
      setPollenTarget(item)
    } else if(shippingData.export_type === EXPORT_TYPE.BUNCH){
      setBunchTarget(item)
    }
  }

  const handleCloseModal = () => {
    setPollenTarget(null)
    setBunchTarget(null)
  }

  const handleReceivedProduct = async() => {
    setErrorMessages(null)
    try {
      await POST(`${URL_DELIVERY.DELIVERY_TRANSACTION}${params.id}/${DELIVERY_TRANSACTION_STATE.RECEIVED}/`)
      navigate(PATH.RECEIVER)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const productionTypeDropdown = useDropdownItem({
    url: URL_COMMON.PRODUCTION_TYPE, 
    manual: false, pagination: true, 
    params: { page_size: 999 } 
  })

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      render : (_, original) => (
        <Row gutter = {8} justify='center'>
          <Col>
            <ColorButton type={"primary"} icon={<FileDoneOutlined />} override={colorInfo} onClick={() => handleEvaluateButton(original)}/>
          </Col>
        </Row>
      ),
      hidden : ((_.get(shippingData, 'state', null) === DELIVERY_TRANSACTION_STATE.RECEIVED) || fieldView)                                                                
    },
    {
      title: "รหัสช่อ",
      dataIndex: "inflorescence_code",
      render : (value) => (value ? value : '-'),
      searcher: true,
    },
    {
      title: "ลูกผสม",
      dataIndex: "inflorescence_species_name",
      render : (value) => (value ? value : '-'),
      searcher : true,
    },
    {
      title: "Material Code",
      dataIndex: "inflorescence_species_material",
      searcher: true,
    },
    {
      title: "สถานะผลิต",
      dataIndex: "inflorescence_production_type",
      hidden: fieldView,
      render : (value) => (value ? value : '-'),
      searcher: true,
      searcherOptions: productionTypeDropdown.options
    },
    {
      title: "วันที่รับ",
      dataIndex: "officer_evaluate_datetime",
      hidden: fieldView,
      render : (value) => ( value ? dateToString(value) : '-'),
      searcher: true,
      dateSearcher: true,
    },
    {
      title: "ผลการประเมิน",
      dataIndex: "is_qc_passed",
      hidden: fieldView,
      renderReport: (value) => (value ? "ผ่าน" : "ไม่ผ่าน"),
      render : (value) => (
        <Row justify='center'>
          {value === true ? <CheckCircleFilled style ={{ color : colorSuccess, fontSize : 20}}/> : value === false ? <CloseCircleFilled style ={{ color : colorError, fontSize : 20}}/> : '-'}
        </Row>
      ),
      searcher: true,
      searcherOptions: [
        { value: true, label: "ผ่าน" },
        { value: false, label: "ไม่ผ่าน" },
      ]
    },
    {
      title: "เกรด",
      dataIndex: "grade_name",
      render : (value) => (value ? value : '-'),
      hidden : ((_.get(shippingData, 'export_type', null) === EXPORT_TYPE.BUNCH ? false : true) || fieldView),
      searcher: true,
    },
    {
      title: "น้ำหนัก (กก.)",
      dataIndex: "weight",
      render : (value) => (
        value ? value : '-'
      ),
      hidden : ((_.get(shippingData, 'export_type', null) === EXPORT_TYPE.BUNCH ? false : true) || fieldView),
      searcher: false,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "officer_note",
      hidden: fieldView,
      render : (value) => (value ? value : '-'),
      searcher: true,
    },
    // For field view
    {
      title: "สวนประเมิน",
      hidden: !fieldView,
      renderReport: (value, original) => (original.is_passed_by_worker ? "ผ่าน" : "ไม่ผ่าน"),
      children: [
        {
          title: "ผลประเมิน",
          dataIndex: "is_passed_by_worker",
          render : (value) => (
            <Row justify='center'>
              {value === true ? <CheckCircleFilled style ={{ color : colorSuccess, fontSize : 20}}/> : value === false ? <CloseCircleFilled style ={{ color : colorError, fontSize : 20}}/> : '-'}
            </Row>
          )
        },
        {
          title: "หมายเหตุ",
          dataIndex: "worker_note",
          render : (value, original) => (value 
            ? original.worker_note ? original.worker_note : "-" 
            : original.worker_reject_reason ? original.worker_reject_reason : "-")
        },
        {
          title: "ผู้ประเมิน",
          dataIndex: "evaluated_worker_name"
        }
      ],
    },
    {
      title: "ประเภททะลาย",
      hidden: !fieldView,
      renderReport: (value, original) => {
        return (original.bunch_status === BUNCH_STATUS.RAW) ? "ดิบ/กึ่งสุก" 
          : (original.bunch_status === BUNCH_STATUS.RIPE) ? "สุก" 
          : (original.bunch_status === BUNCH_STATUS.OVERRIPE) ? "สุกเกิน" : "เน่า"
      },
      children: [
        {
          title: "ดิบ/กึ่งสุก",
          dataIndex: "bunch_status",
          render: (value) => (
            <div>
              {(value === BUNCH_STATUS.RAW) ? 
                <CheckCircleFilled style ={{ color : colorSuccess, fontSize : 20}}/> 
                : null}
            </div>
          )
        },
        {
          title: "สุก",
          dataIndex: "bunch_status",
          render: (value) => (
            <div>
              {(value === BUNCH_STATUS.RIPE) ? 
                <CheckCircleFilled style ={{ color : colorSuccess, fontSize : 20}}/> 
                : null}
            </div>
          )
        },
        {
          title: "สุกเกิน",
          dataIndex: "bunch_status",
          render: (value) => (
            <div>
              {(value === BUNCH_STATUS.OVERRIPE) ? 
                <CheckCircleFilled style ={{ color : colorSuccess, fontSize : 20}}/> 
                : null}
            </div>
          )
        },
        {
          title: "เน่า",
          dataIndex: "bunch_status",
          render: (value) => (
            <div>
              {(value === BUNCH_STATUS.ROTTEN) ? 
                <CheckCircleFilled style ={{ color : colorSuccess, fontSize : 20}}/> 
                : null}
            </div>
          )
        }
      ],
    },
  ]

  // Export to pdf
  const toPrintReport = async (url, params={}) => {
    if (!url) {
      return;
    }

    setErrorMessages(null);
    setPrinting(true);
    try {
      const response = await GET(url, params, false, true)
      window.open(URL.createObjectURL(response.data));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { setPrinting(false) }
  }

  const printSAPReport = (export_type) => {
    let params = { export_type }
    if (tableRef.current) {
      params = { ...params, ...tableRef.current.getFilterParams() }
    }
    toPrintReport(URL_REPORT.DELIVERY_SAP_REPORT, params)
  }

  const updateDeliverySAPNo = async (values) => {
    setErrorMessages(null)
    setLoading(true)
    try {
      await PATCH(`${URL_DELIVERY.DELIVERY_TRANSACTION}${params.id}/`, values)
      message.success({ content: "บันทึกเลขที่เอกสารตรวจรับสำเร็จ" })
      fetchShippingData()
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    fetchShippingData();
  },[])

  React.useEffect(() => {
    if(shippingData){
      setIsInflorescencesDone(shippingData && shippingData.inflorescences_number === shippingData.accepted_number + shippingData.rejected_number)
    }
  },[shippingData])

  React.useEffect(() => {
    if (searchParams)
      setFieldView(searchParams.get("field_view") === "true");

  }, [searchParams])

  return(
    <Spin spinning={loading}>
      <HeaderScreen title ={`ใบส่งของ - ${_.get(shippingData, 'code', null) ? _.get(shippingData, 'code', null) : '-'}`}/>
        <Layout style={{ minHeight: '90vh', background : WHITE_COLOR_BG }}>
        <Content
        style={{
          padding: '0px 48px',
          marginTop: 24
        }}
      >
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }
        <Descriptions bordered size='small' column={4} style={{ marginBottom : 16 }}>
          <Descriptions.Item label="ประเภทใบส่งของ">{_.get(EXPORT_TYPE_TH, [_.get(shippingData, 'export_type', '-')] , '-')} </Descriptions.Item>
          <Descriptions.Item label="วันที่ส่ง">{_.get(shippingData, 'sent_date', '-')}</Descriptions.Item>
          <Descriptions.Item label="ทะเบียนรถ">{_.get(shippingData, 'vehicle', '-')}</Descriptions.Item>
          <Descriptions.Item label={_.get(shippingData, 'driver', '-')} span={2} contentStyle={{ display: 'none'}}/>
          <Descriptions.Item label="ผู้ส่ง" span={2}>{_.get(shippingData, 'sender_name', '-')}</Descriptions.Item>
          <Descriptions.Item label="ผู้รับ" span={2}>{_.get(shippingData, 'receiver_name', '-')}</Descriptions.Item>
          <Descriptions.Item label="หมายเหตุ">{_.get(shippingData, 'note', '-')}</Descriptions.Item>
        </Descriptions>

        <Flex justify="space-between">
          <div>
            <Form form={form} onFinish={updateDeliverySAPNo} layout="inline">
              <Form.Item name="sap_report_code" label="เลขที่เอกสารตรวจรับ">
                <Input/>
              </Form.Item>
              <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">บันทึก</Button>
            </Form>
          </div>
          <Flex justify="end" gap="small">
            {(_.get(shippingData, "state", null) !== DELIVERY_TRANSACTION_STATE.RECEIVED) && (!fieldView) && (
              <ColorButton type="primary" override={colorSuccess} onClick={() => handleEvaluateButton("add")}>
                + รับเข้า
              </ColorButton>
            )}
            <Button icon={<FilePdfOutlined />} loading={printing} onClick={() => printSAPReport("pdf")}>
              PDF
            </Button>
            <Button icon={<FileExcelOutlined />} loading={printing} onClick={() => printSAPReport("excel")}>
              Excel
            </Button>
          </Flex>
        </Flex>
        
      <TSTable
        ref={tableRef}
        columns={columns}
        style={{ marginTop : "1rem" }}
        rowKey={"id"}
        url={URL_DELIVERY.INFLORESCENCE_DELIVERY}
        params={{ is_active : true, delivery_transaction : params.id}}
        size={"small"}
        pageSize={10}
      />

      </Content>
      <Footer>
        {(_.get(shippingData, 'state', null) !== DELIVERY_TRANSACTION_STATE.RECEIVED) && (!fieldView) && <ColorButton 
          type={'primary'} 
          override={colorInfo} 
          style={{ float : 'right', marginBottom : 16}} 
          disabled={isInflorescencesDone ? false : true}
          onClick={() => {
            Modal.confirm({
              title: `ยืนยันรับสินค้า - ${_.get(shippingData, 'code', null)}`,
              content: 'คุณต้องการยืนยันรับสินค้าใช่หรือไม่',
              okText: 'ยืนยัน',
              icon: <InfoCircleOutlined />,
              okButtonProps: {style: { background : colorInfo}},
              maskClosable: true,
              onOk: () => {
                handleReceivedProduct();
              },
              cancelText: 'ยกเลิก',
            })
          }}
          shape="round" 
          size={'large'} 
          icon={<CheckSquareOutlined />}> 
            ยืนยันรับเข้าสินค้า
        </ColorButton>}

        {fieldView && (
          <div style={{textAlign: "right"}}>
            <Button
              loading={printing}
              style={{marginRight: 8, color: colorInfo}}
              type={'dashed'} 
              size={'large'} 
              icon={<FilePdfOutlined />}
              onClick={() => {
                toPrintReport(`${URL_REPORT.DELIVERY_TRANSACTION_COVER_REPORT}${params.id}/`)
              }}>
              ใบส่งทะลาย
            </Button>

            <ColorButton 
              loading={printing}
              style={{color: colorError}}
              type={'dashed'} 
              size={'large'} 
              icon={<FilePdfOutlined />}
              onClick={() => {
                toPrintReport(`${URL_REPORT.BUNCH_DELIVERY_PREPARING_REPORT}?delivery_transaction=${params.id}`)
              }}>
              รายงานทะลายเตรียมส่งโรงาน
            </ColorButton>
          </div>
        )}
      </Footer>
      </Layout>
      
      <EvaluateBunchModal
        open={bunchTarget ? true : false}
        onClose={() => handleCloseModal()}
        onUpdate={() => {
          handleCloseModal()
          refreshData();
        }}
        shippingData={shippingData}
        target={bunchTarget}
      />

      <EvaluatePollenModal
        open={pollenTarget ? true : false}
        onClose={() => handleCloseModal()}
        onUpdate={() => {
          handleCloseModal()
          refreshData();
        }}
        shippingData={shippingData}
        target={pollenTarget}
      />
      

    </Spin>
  )
}